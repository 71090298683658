// ES6 modules
import sal from 'sal.js'

document.addEventListener('DOMContentLoaded', () => {

    sal({ once: true, threshold: 0.1, duration: 750 }) // animations library

})


// Pobranie referencji do formularza


const form = document.querySelector('form[name="contact"]');


// Funkcja obsługująca walidację formularza
function validateForm(event) {
    event.preventDefault(); // Zapobiegaj domyślnej akcji przeglądarki, czyli odświeżaniu strony po wysłaniu formularza

    // Pobranie wartości pól formularza
    const firstNameInput = document.querySelector('input[name="firstName"]');
    const phoneNumberInput = document.querySelector('input[name="phoneNumber"]');
    const emailInput = document.querySelector('input[name="email"]');
    const messageInput = document.querySelector('textarea[name="message"]');

    const firstName = firstNameInput.value.trim();
    const phoneNumber = phoneNumberInput.value.trim();
    const email = emailInput.value.trim();
    const message = messageInput.value.trim();

    // Walidacja pól formularza
    if (firstName === '') {
        displayErrorMessage(firstNameInput, 'Proszę wprowadzić imię');
        return;
    } else {
        clearErrorMessage(firstNameInput);
    }


    if (phoneNumber === '') {
        displayErrorMessage(phoneNumberInput, 'Proszę wprowadzić numer telefonu');
        return;
    } else if (!validatePhoneNumber(phoneNumber)) {
        displayErrorMessage(phoneNumberInput, 'Nieprawidłowy format numeru telefonu');
        return;
    } else {
        clearErrorMessage(phoneNumberInput);
    }

    if (email === '') {
        displayErrorMessage(emailInput, 'Proszę wprowadzić adres email');
        return;
    } else if (!validateEmail(email)) {
        displayErrorMessage(emailInput, 'Nieprawidłowy format adresu email');
        return;
    } else {
        clearErrorMessage(emailInput);
    }

    if (message === '') {
        displayErrorMessage(messageInput, 'Proszę wprowadzić treść wiadomości');
        return;
    } else {
        clearErrorMessage(messageInput);
    }

    // Jeśli formularz jest poprawny, możesz wysłać dane gdziekolwiek chcesz
    // np. używając AJAX lub innej techniki

    // Wywołaj funkcję do wysłania formularza
    submitForm();
}

// Funkcja pomocnicza do wyświetlania komunikatu o błędzie
function displayErrorMessage(inputField, errorMessage) {
    const formControl = inputField.parentElement;
    const errorElement = formControl.querySelector('.error-message');

    if (!errorElement) {
        const errorDiv = document.createElement('div');
        errorDiv.classList.add('error-message');
        errorDiv.textContent = errorMessage;
        formControl.appendChild(errorDiv);
    } else {
        errorElement.textContent = errorMessage;
    }
}

// Funkcja pomocnicza do ukrywania komunikatu o błędzie
function clearErrorMessage(inputField) {
    const formControl = inputField.parentElement;
    const errorElement = formControl.querySelector('.error-message');

    if (errorElement) {
        formControl.removeChild(errorElement);
    }
}

// Funkcja pomocnicza do sprawdzania poprawności numeru telefonu
function validatePhoneNumber(phoneNumber) {
    const phoneRegex = /^\d{9}$/; // Ustal odpowiedni wzór dla swojego formatu numeru telefonu
    return phoneRegex.test(phoneNumber);
}

// Funkcja pomocnicza do sprawdzania poprawności adresu email
function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

// Funkcja do wysłania formularza
function submitForm() {
    const formData = new FormData(form); // Utwórz obiekt FormData na podstawie formularza

    fetch('./form.php', {
        method: 'POST',
        body: formData
    })
        .then(response => response.text())
        .then(data => {
            // Tutaj można dodać kod obsługujący odpowiedź serwera (np. wyświetlanie komunikatu sukcesu lub błędu)


            // Wyczyść zawartość pól formularza po wysłaniu
            document.querySelector('input[name="firstName"]').value = '';
            document.querySelector('input[name="phoneNumber"]').value = '';
            document.querySelector('input[name="email"]').value = '';
            document.querySelector('textarea[name="message"]').value = '';

            // Utwórz nowy element div dla komunikatu "Dziękujemy"
            const afterSend = document.createElement('div');
            afterSend.id = 'afterSend';
            afterSend.classList.add('after-send')
            afterSend.textContent = 'Dziękujemy za wysłanie wiadomości!';

            // Dodaj komunikat "Dziękujemy" do elementu nadrzędnego formularza
            form.parentElement.appendChild(afterSend);
        })
        .catch(error => {
            // Tutaj można dodać kod obsługujący błąd żądania (np. wyświetlanie komunikatu o błędzie)
            console.error(error);
        });
}

// Nasłuchiwanie na zdarzenie submit dla formularza
form.addEventListener('submit', validateForm);

const quoteWrappers = document.querySelectorAll(".quote__wrapper");

// Ustawienie klasy .active dla pierwszego .quote__wrapper
quoteWrappers[0].classList.add("active");

let currentIndex = 0;

function setActiveQuote() {
    // Usunięcie klasy .active ze wszystkich .quote__wrapper
    quoteWrappers.forEach((quoteWrapper) => {
        quoteWrapper.classList.remove("active");
    });

    // Dodanie klasy .active do aktualnego .quote__wrapper
    quoteWrappers[currentIndex].classList.add("active");

    // Zwiększenie indeksu lub resetowanie go do zera, jeśli osiągnie ostatni .quote__wrapper
    currentIndex = (currentIndex + 1) % quoteWrappers.length;
}

// Uruchomienie funkcji setActiveQuote co 3 sekundy (lub inny wybrany interwał czasowy)
setInterval(setActiveQuote, 5000);

const hamburger = document.querySelector('.hamburger');
const body = document.querySelector('body');
const headerMenu = document.querySelector('.header__menu');

hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('active');
    headerMenu.classList.toggle('active');

    if (headerMenu.classList.contains('active')) {
        body.style.overflow = 'hidden';
        headerMenu.setAttribute('aria-expanded', 'true');
        headerMenu.focus();
    } else {
        body.style.overflow = '';
        headerMenu.setAttribute('aria-expanded', 'false');

    }
});

headerMenu.addEventListener('click', (event) => {
    if (event.target.tagName === 'A') {
        hamburger.classList.remove('active');
        headerMenu.classList.remove('active');
        body.style.overflow = '';
    }
});




const calculateHeaderHeight = () => {

    const header = document.querySelector('header');

    const runCalculation = () => {
        if (header) {
            const headerHeight = header.offsetHeight;
            document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
        }
    };

    window.addEventListener('resize', runCalculation);
    runCalculation();
};
calculateHeaderHeight();


const headerWrapper = document.querySelector('.header__wrapper');

window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 50) {
        headerWrapper.classList.add('header__wrapper--full');
    } else {
        headerWrapper.classList.remove('header__wrapper--full');
    }
});


// Pobierz elementy o klasach tabs__tab i tabs__content
const tabs = document.querySelectorAll('.tabs__tab');
const contents = document.querySelectorAll('.tabs__content');

// Dodaj zdarzenie kliknięcia do każdego elementu tab
tabs.forEach((tab, index) => {
    tab.addEventListener('click', () => {
        // Usuń klasę tab__content--active ze wszystkich elementów
        contents.forEach(content => {
            content.classList.remove('tabs__content--active');
        });

        // Dodaj klasę tab__content--active do odpowiedniego elementu na podstawie indeksu
        contents[index].classList.add('tabs__content--active');
    });
});


